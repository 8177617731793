import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { ServiceElement } from './ServiceElement';
import { ResponsiveImage } from './ResponsiveImage';
import '../style/services.scss';

import autoriparazioni from '../images/icons/services/screwdriver-and-wrench-crossed.svg';
import elettrauto from '../images/icons/services/car-battery.svg';
import pneumatici from '../images/icons/services/car-wheel.svg';
import assistenza from '../images/icons/services/truck-carrying-car.svg';
import revisioni from '../images/icons/services/mechanic-user.svg';
import ricambi from '../images/icons/services/sports-car.svg';

export class Services extends Component {
	render() {
		return (
			<div className="services">
				<Helmet>
					<title>Officina Zatti Silvano S.a.s. | Servizi</title>
				</Helmet>
				<div className="pageTitle">
					<ResponsiveImage filePath={process.env.PUBLIC_URL + "/pageTitles/servizi/services"} sizes="100vw" altText="servizi"></ResponsiveImage>
					<div className="pageTitleContent">
						<h1 className="text">I nostri <strong>servizi</strong></h1>
					</div>
				</div>
				<div className="pageContent">
					<section>
						<ul className="servicesList">
							<ServiceElement background={autoriparazioni}>AUTORIPARAZIONI</ServiceElement>
							<ServiceElement background={elettrauto}>ELETTRAUTO</ServiceElement>
							<ServiceElement background={pneumatici}>SERVIZIO PNEUMATICI</ServiceElement>
							<ServiceElement background={assistenza}>ASSISTENZA STRADALE</ServiceElement>
							<ServiceElement background={revisioni}>SERVIZIO REVISIONI</ServiceElement>
							<ServiceElement background={ricambi}>RICAMBI E <span>ACCESSORI AUTO</span></ServiceElement>
						</ul>
					</section>
				</div>
			</div>
		);
	}
}
