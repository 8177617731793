import React from 'react';
import { ResponsiveImage } from './ResponsiveImage';

export class ServiceBoxHome extends React.Component {
	constructor(props) {
		super(props);
		this.serviceBox = React.createRef();
		this.state = {
			inViewport: false
		}
	}

	listenScrollEvent = () => {
		const offsetTop = this.serviceBox.current.getBoundingClientRect().top;

		if (offsetTop < window.innerHeight) {
			this.setState({inViewport: true});
			window.removeEventListener('scroll', this.listenScrollEvent);
		}
	}

	componentDidMount() {
		window.addEventListener('scroll', this.listenScrollEvent);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.listenScrollEvent);
	}

	render() {
		return (
			<div className={"serviceBox " + this.props.className + (this.state.inViewport ? ' animate' : '')} ref={this.serviceBox}>
				<div className="serviceImage">
					<ResponsiveImage filePath={process.env.PUBLIC_URL + "/homeServices/" + this.props.className + "/" + this.props.className} sizes="280px" altText={this.props.className}></ResponsiveImage>
				</div>
				<div className="serviceBottom">
					<div className="serviceIcon"></div>
					<div className="serviceTitle">{this.props.title}</div>
					<div className="serviceText">
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}