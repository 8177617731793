import React, { Component } from 'react';

export class Timetable extends Component {
	openingHours = [{
		day: "Lunedì",
		morning: "8:00 - 12:00",
		afternoon: "14:30 - 18:30",
		dayNum: 1
	},
	{
		day: "Martedì",
		morning: "8:00 - 12:00",
		afternoon: "14:30 - 18:30",
		dayNum: 2
	},
	{
		day: "Mercoledì",
		morning: "8:00 - 12:00",
		afternoon: "14:30 - 18:30",
		dayNum: 3
	},
	{
		day: "Giovedì",
		morning: "8:00 - 12:00",
		afternoon: "14:30 - 18:30",
		dayNum: 4
	},
	{
		day: "Venerdì",
		morning: "8:00 - 12:00",
		afternoon: "14:30 - 18:30",
		dayNum: 5
	},
	{
		day: "Sabato",
		morning: "8:00 - 12:00",
		dayNum: 6
	},
	{
		day: "Domenica",
		dayNum: 0
	}];

	render() {
		return (
			<table>
				<tbody>
					{this.openingHours.map(({day, morning, afternoon, dayNum}, index) => 
						<tr key={index} className={new Date().getDay() === dayNum ? 'active' : ''}>
							<td>{day}</td>
							<td className={!morning ? 'closed' : ''} colSpan={!morning && !afternoon ? '2' : ''}>{morning ? morning : 'chiuso'}</td>
							{morning && <td className={!afternoon ? 'closed' : ''}>{afternoon ? afternoon : 'chiuso'}</td>}
						</tr>
					)}
				</tbody>
			</table>
		);
	}
}
