import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Timetable } from './Timetable';
import { Map } from './Map';
import { ResponsiveImage } from './ResponsiveImage';
import '../style/contact.scss';

export class Contact extends Component {	
	render() {
		return (
			<div className="infoContacts">
			<Helmet>
					<title>Officina Zatti Silvano S.a.s. | Contattaci</title>
				</Helmet>
				<div className="pageTitle">
					<ResponsiveImage filePath={process.env.PUBLIC_URL + "/pageTitles/contatti/officina-front"} sizes="100vw" altText="contatti"></ResponsiveImage>
					<div className="pageTitleContent">
						<h1 className="text">Informazioni e <strong>contatti</strong></h1>
					</div>
				</div>
				<div className="pageContent">
					<section className="contacts box">
						<h2>Contatti</h2>
						<div className="address boxSection">
							<div className="icon"></div>
							<span className="text">strada Corte Longhera, 2/4<br />46010 - Cesole (MN)</span>
						</div>
						<div className="phone boxSection">
							<div className="icon"></div>
							<span className="text">
								<a href="tel:+390376969310">+39 0376 969310</a><br />
								<a href="tel:+390376969048">+39 0376 969048</a>
							</span>
						</div>
						<div className="fax boxSection">
							<div className="icon"></div>
							<span className="text">+39 0376 969048</span>
						</div>
						<div className="mail boxSection">
							<div className="icon"></div>
							<span className="text">s.zatti@libero.it</span>
						</div>
					</section>
					<section className="times box">
						<h2>Orari</h2>
						<Timetable />
					</section>
					<section className="map">
						<h2>Passa a trovarci</h2>
						<div className="mapWrapper wrapper">
							<Map></Map>
						</div>
					</section>
				</div>
			</div>
		);
	}
}
