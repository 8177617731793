import React, { Component } from 'react';
import '../style/service-element.scss';

export class ServiceElement extends Component {
    render () {
        return (
            <li className="serviceElement">
                <div className="icon" style={{backgroundImage : 'url(' + this.props.background + ')'}}></div>
                <div className="text">{this.props.children}</div>
            </li>
        );
    }
} 