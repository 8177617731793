import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Gallery } from '../components/Gallery';
import { ResponsiveImage } from './ResponsiveImage';
import '../style/about.scss';

export class About extends Component {
	render() {
		return (
			<div className="about">
				<Helmet>
					<title>Officina Zatti Silvano S.a.s. | Chi siamo</title>
				</Helmet>
				<div className="pageTitle">
					<ResponsiveImage filePath={process.env.PUBLIC_URL + "/pageTitles/chi-siamo/chi-siamo"} sizes="100vw" altText="chi siamo"></ResponsiveImage>
					<div className="pageTitleContent">
						<h1 className="text">Chi <strong>siamo</strong></h1>
					</div>
				</div>
				<div className="pageContent">
					<section className="story wrapper">
						<div className="mainImage">
							<ResponsiveImage filePath={process.env.PUBLIC_URL + "/gallery/mainImage/mainImage"} sizes="(min-width: 768px) 370px, 100vw" altText="Claudio, Silvano e Paolo Zatti"></ResponsiveImage>
						</div>
						<div className="text">
							<h2>Officina <span>Zatti Silvano</span></h2>
							<p>Nasce a Cesole alla fine degli anni '60 dalla passione di <strong>Silvano Zatti</strong>, il cui impegno e dedizione l'hanno resa un punto di riferimento nel territorio.<br/>Gestita successivamente dai figli <strong>Claudio</strong> e <strong>Paolo</strong>, da ormai più di 50 anni garantisce massima professionalità e competenza nel settore.<br/>Nel 1974 entra a far parte della grande famiglia <span><strong>Alfa Romeo</strong></span>, diventando officina autorizzata di questo importante marchio.<br/>Da allora il cuore sportivo non ha mai smesso di battere.</p>
						</div>
					</section>
					<section className="gallery">
						<Gallery />
					</section>
				</div>
			</div>
		);
	}
}