import { Component } from 'react';
import { withRouter } from 'react-router'
import ReactGA from "react-ga";  //Google Analytics 
import Cookies from 'universal-cookie';

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);

            const cookies = new Cookies();
            const cookieConsent = cookies.get('CookieConsent');
            
            if (cookieConsent) {
                ReactGA.set({ page: window.location.pathname }); // Update the user's current page
                ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
            }
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);