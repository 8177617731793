import React, { Component } from 'react';
import { ResponsiveImage } from './ResponsiveImage';
import '../style/gallery.scss';

export class Gallery extends Component {
	images = [
		{name: "gallery1", size: "(min-width: 768px) 33vw, 50vw"},
		{name: "gallery2", size: "(min-width: 768px) 66vw, 50vw" },
		{name: "gallery3", size: "(min-width: 768px) 66vw, 100vw" },
		{name: "gallery4", size: "(min-width: 768px) 33vw, 50vw" },
		{name: "gallery5", size: "(min-width: 768px) 33vw, 50vw" },
		{name: "gallery6", size: "(min-width: 768px) 50vw, 25vw" },
		{name: "gallery7", size: "(min-width: 768px) 50vw, 75vw" },
		{name: "gallery8", size: "100vw" }
	];

	render() {
		return (
			<div className="galleryWrapper wrapper">
				{this.images.map((image, i) =>
					<div key={image.name} className={`image image${i + 1}`}>
						<ResponsiveImage filePath={process.env.PUBLIC_URL + "/gallery/" + image.name + "/" + image.name} sizes={image.size} altText={image.name}></ResponsiveImage>
					</div>
				)}
			</div>
		)
	}
}