import React, { Component } from 'react';
import Slider from "react-slick";
import { ResponsiveImage } from './ResponsiveImage';
import "../style/slideshow.scss";


export class Slideshow extends Component {
	render() {
		var settings = {
			fade: true,
			dots: true,
			infinite: true,
			speed: 1000,
			autoplaySpeed: 4000,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			autoplay: true,
			adjustHeight: true,
			pauseOnHover: false,
			lazyLoad: true
		};

		return (
			<div className="sliderWrapper">
				<Slider {...settings}>
					<div>
						<ResponsiveImage filePath={process.env.PUBLIC_URL + "/homeSlider/slide1/slide1"} sizes="100vw" altText="slide1"></ResponsiveImage>
					</div>
					<div>
						<ResponsiveImage filePath={process.env.PUBLIC_URL + "/homeSlider/slide2/slide2"} sizes="100vw" altText="slide2"></ResponsiveImage>
					</div>
					<div>
						<ResponsiveImage filePath={process.env.PUBLIC_URL + "/homeSlider/slide3/slide3"} sizes="100vw" altText="slide3"></ResponsiveImage>
					</div>
				</Slider>
			</div>
		);
	}
}