import React, { Component } from 'react';
import NavLink from 'react-router-dom/NavLink';
import { Menu } from './Menu';
import { OfficinaAutorizzata } from './OfficinaAutorizzata';

export class Header extends Component {
	constructor(props) {
		super(props);

		this.menuRef = React.createRef();

		this.toggleMenu = this.toggleMenu.bind(this);
		this.hardCloseMenu = this.hardCloseMenu.bind(this);
		this.openMenu = this.openMenu.bind(this);

		this.state = {
			menuOpen: false,
			hidden: false
		}
	}

	fixBody() {
		document.body.style.top = `-${window.scrollY}px`;
		document.body.style.position = 'fixed';
	}

	unfixBody () {
		const scrollY = document.body.style.top;
		document.body.style.position = '';
		document.body.style.top = '';
		window.scrollTo(0, parseInt(scrollY || '0') * -1);
	}

	openMenu() {
		this.setState({ menuOpen: true });
		this.fixBody();
	}

	closeMenu() {
		this.setState({ menuOpen: false });
		this.unfixBody();
	}

	hardCloseMenu() {
		if (window.matchMedia("(max-width: 959px)").matches) {
			this.setState({ hidden: true, menuOpen: false });
			setTimeout(() => this.setState({ hidden: false }), 600);
			this.unfixBody();
		}
	}

	toggleMenu() {
		if (!this.state.menuOpen) {
			this.openMenu();
		} else {
			this.closeMenu();
		}
	}

	render() {
		return (
			<header className={"header" + (this.state.menuOpen ? " menuOpen" : "")}>
				<div className="phoneBar hide-mobile">
					<span className="text">ASSISTENZA STRADALE</span>
					<span className="tel"><a href="tel:+390376969310">0376 969310</a></span>
				</div> 
				<div className="mainHeader">
					<div className={"hamburger" + (this.state.menuOpen ? " open" : "")} onClick={this.toggleMenu}>
						<div className="icon"></div>
					</div>
					<div className="logo">
						<NavLink exact to="/" onClick={this.hardCloseMenu}>
							<div className="logoTop">Officina Meccanica</div>
							<div className="logoTitle">ZATTI SILVANO<span>S.A.S.</span></div>
							<div className="logoSubtitle">dei F.lli Zatti Claudio, Paolo & C.</div>
						</NavLink>
					</div>
				</div>
				<Menu ref={this.menuRef} open={this.state.menuOpen} onClickMenuItem={this.hardCloseMenu} hidden={this.state.hidden} />
				<OfficinaAutorizzata className="hide-mobile"/>
			</header>
		);
	}
}
