import React, { Component } from 'react';
import NavLink from 'react-router-dom/NavLink';

export class Menu extends Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef();
	}

	render() {
		return (
			<div ref={this.myRef} className={"menu" + (this.props.hidden ? " menu-hidden" : "") + (this.props.open ? " open" : "")}>
				<nav>
					<ul>
						<li className="home"><NavLink exact to="/" activeClassName="active" onClick={this.props.onClickMenuItem}><span>HOME</span></NavLink></li>
						<li className="about"><NavLink to="/chi-siamo" activeClassName="active" onClick={this.props.onClickMenuItem}><span>CHI SIAMO</span></NavLink></li>
						<li className="services"><NavLink to="/servizi" activeClassName="active" onClick={this.props.onClickMenuItem}><span>SERVIZI</span></NavLink></li>
						<li className="contact"><NavLink to="/contatti" activeClassName="active" onClick={this.props.onClickMenuItem}><span>CONTATTACI</span></NavLink></li>
					</ul>
				</nav>
				<div className="phoneBar hide-desktop">
					<span className="text">ASSISTENZA STRADALE</span>
					<span className="tel"><a href="tel:+390376969310">0376 969310</a></span>
				</div>
			</div>
		);
	}
}
