import React, { Component } from 'react';

export class OfficinaAutorizzata extends Component {

	render() {
		return (
            <div className={"alfa " + this.props.className} >
                <a href="http://alfaromeo.mopar.eu/it-it/DealerPages/1000-83-0031050-000.aspx" target="_blank" rel="noopener noreferrer">
                	<div className="alfaLogo" title="Questa immagine rappresenta un marchio registrato o di fabbrica.">
                    </div>
                    <div className="alfaText">OFFICINA AUTORIZZATA</div>
                </a>
            </div>
		);
	}
}
