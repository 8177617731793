import React, { Component } from 'react';

export class ResponsiveImage extends Component {    
	render() {
		return (
                <img 
                    srcSet={this.props.filePath + "-640w.jpg 640w," +
                            this.props.filePath + "-750w.jpg 750w," +
                            this.props.filePath + "-828w.jpg 828w," +
                            this.props.filePath + "-1080w.jpg 1080w," +
                            this.props.filePath + "-1280w.jpg 1280w," +
                            this.props.filePath + "-1440w.jpg 1440w," +
                            this.props.filePath + "-1536w.jpg 1536w," +
                            this.props.filePath + "-1920w.jpg 1920w," +
                            this.props.filePath + "-2048w.jpg 2048w"
                        }
                    sizes={this.props.sizes}
                    src={this.props.filePath + ".jpg"}
                    alt={this.props.altText}
                    data-object-fit="cover" />
		);
	}
}