import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { ServiceBoxHome } from './ServiceBoxHome';
import { Slideshow } from './Slideshow';
import HoverFillButton from 'react-hover-fill-button';
import NavLink from 'react-router-dom/NavLink';
import '../style/home.scss';

export class Home extends Component {	
	render() {
		return (
			<div className="home">
				<Helmet>
					<title>Officina Zatti Silvano S.a.s. | Cesole, MN</title>
				</Helmet>
				<div className="slideshow">
					<Slideshow></Slideshow>
					<div className="mainDescription">
						<span className="text">
							<span className="title"><strong>50 ANNI</strong><br />DI ESPERIENZA<br /></span>
							maturata nel settore automobilistico, disponibilità e cortesia fanno di noi un punto di riferimento per la nostra clientela.
						</span>
					</div>
				</div>

				<div className="servicesSection">
					<ServiceBoxHome className="autoriparazioni" title="Autoriparazioni">Riparazioni di autoveicoli di ogni marca e modello, con la massima professionalità, disponibilità e competenza. </ServiceBoxHome>
					<ServiceBoxHome className="gommista" title="Gommista">Assistenza ed installazione di pneumatici dei migliori marchi sul mercato, per garantire qualità e sicurezza.</ServiceBoxHome>
					<ServiceBoxHome className="assistenza-stradale" title="Assistenza Stradale">In caso di guasto o incidente offriamo un servizio di assistenza stradale rapido ed efficiente.</ServiceBoxHome>
					<div className="allServices">
						<NavLink to="/servizi" activeClassName="active">
							<HoverFillButton fillTextColor="#FFF" fillBackground="#C42537" textColor="#313135" background="#FFF" borderWidth="3px">Scopri tutti i nostri servizi</HoverFillButton>
						</NavLink>
					</div>
				</div>
			</div>
		);
	}
}
