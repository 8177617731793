import React, { Component } from 'react';
import ReactGA from "react-ga";  //Google Analytics 
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Home } from './components/Home';
import { About } from './components/About';
import { Services } from './components/Services';
import { Contact } from './components/Contact';
import { OfficinaAutorizzata } from './components/OfficinaAutorizzata';
import CookieConsent from "react-cookie-consent";
import Cookies from 'universal-cookie';
import "@babel/polyfill";
import 'objectFitPolyfill';
import './App.scss';

function initGA () {
	ReactGA.initialize('G-L19M3Y12CV');
	ReactGA.set({ anonymizeIp: true });	

	ReactGA.set({ page: window.location.pathname }); // Update the user's current page
	ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
	console.log('GA4 loaded');
}

class App extends Component {
	componentDidMount() {
		const cookies = new Cookies();
		const cookieConsent = cookies.get('CookieConsent');

		if (cookieConsent) {
			initGA();
		}
	}

	render() {
		const history = createBrowserHistory(); 

		return (
			<Router history={history} basename="/">
				<ScrollToTop>
					<div className="App" id="mainContainer">
						<Header />
						<main id="mainContent">
							<OfficinaAutorizzata className="hide-desktop" />
							<Switch>
								<Route exact path="/" component={Home} />
								<Route path="/chi-siamo" component={About} />
								<Route path="/servizi" component={Services} />
								<Route path="/contatti" component={Contact} />
							</Switch>
						</main>
						<Footer />
						<CookieConsent
							containerClasses="cookie-layer"
							buttonText="HO CAPITO"
							style={{ background: "#C42537", fontSize: "12px"}}
							buttonStyle={{ color: "#444", background:"#fff", fontSize: "12px", fontWeight: "bold"}}
							onAccept={() => {initGA();}}
						>Utilizziamo i cookie, anche di terze parti, per offrirti la miglior esperienza di navigazione e analizzare il traffico sul nostro sito.</CookieConsent>
					</div>
				</ScrollToTop>
			</Router>
		);
	}
}

export default App;
