import React, { Component } from 'react';

export class Footer extends Component {
	render() {
		return (
			<footer className="footer">
				<div className="mainFooter">
					<div className="colsWrapper">
						<div className="footerCol">
							<div className="footerColTitle">
								OFFICINA MECCANICA ZATTI SILVANO<br />dei F.lli Zatti Claudio, Paolo & C. S.A.S.</div>
							<div className="footerColInfo">
								<div>Strada Corte Longhera, 2/4</div>
								<div>46010 - Cesole (MN)</div>
								<div>P.IVA 	01988140206</div>
								{/*<div>Reg.Imp. MN 214429</div> {/* DA VERIFICARE */}
							</div>
						</div>
						<div className="footerCol">
							<div className="footerColTitle">CONTATTI</div>
							<div className="footerColInfo">
								<div className="tel">Tel. <a href="tel:+390376969310">+39 0376 969310</a></div>
								<div className="fax">Fax. +39 0376 969048</div>
								<div className="email">
									<a href="mailto:s.zatti@libero.it">s.zatti@libero.it</a>
								</div>
								<div className="socials">
									<div className="facebook social">
										<a href="https://www.facebook.com/OfficinaZattiSilvano/" target="_blank" rel="noopener noreferrer">
											<span className="icon"></span>
										</a>
									</div>
									<div className="instagram social">
										<a href="https://www.instagram.com/officinazattisilvano/" target="_blank" rel="noopener noreferrer">
											<span className="icon"></span>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="footerCol">
							<div className="footerColTitle">ORARI</div>
							<div className="footerColInfo">
								<div className="timetableRow">Lun - Ven: 8:00 - 12:00 | 14:30 - 19:00</div>
								<div className="timetableRow">Sabato: 8:00 - 12:00</div>
								<div className="timetableRow">Domenica chiuso</div>
							</div>
						</div>
					</div>
				</div>
				<div className="bottomFooter">
					<span className="copyright">Copyright &copy; {new Date().getFullYear()} Officina Zatti Silvano S.A.S. - Tutti i diritti riservati - <span>Made with ❤︎️ by Giulia Zatti</span></span>
				</div>
			</footer>
		);
	}
}
