/*global google*/
import React, { Component } from 'react';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker
} from "react-google-maps";
import pin from '../images/icons/pin.svg'; //da sostituire

class MapComponent extends Component {
	render() {
		const lng = 10.653498;
		const lat = 45.065168;

		return (
			<GoogleMap
				defaultZoom={15}
				defaultCenter={{ lat: lat, lng: lng }}
				options={{
					mapTypeControl: false,
					fullscreenControl: false,
					streetViewControl: false,
					styles: [
						{
							featureType: "poi.business",
							stylers: [
								{
									visibility: "simplified"
								}
							]
						}
					]
				}}
			>
				<Marker
					position={{ lat: lat, lng: lng }}
					icon={{ url: pin, scaledSize: new google.maps.Size(50, 50) }}
				/>
			</GoogleMap>
		)
	}
}

const EnhancedMapComponent = withScriptjs(withGoogleMap(MapComponent));

export const Map = props => (
	<EnhancedMapComponent
		googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDagbYSENo9j79qZqzuQGrQbMPJD7cpYRs&language=it&region=IT"
		loadingElement={<div style={{ height: "100%" }} />}
		containerElement={<div style={{ height: "100%" }} />}
		mapElement={<div style={{ height: "100%" }} />}
		{...props}
	/>
);